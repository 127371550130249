import React, { useContext } from "react";
import { StoreContext } from "context/Store";
import { Link } from "react-router-dom";
import styles from "./Logo.module.scss";

const Logo = () => {
  const value = useContext(StoreContext);

  return (
    <div className={styles["container"]}>
      <Link
        to={"/"}
        aria-current="page"
        style={{
          textDecoration: "none",
          color: value?.bgColor === "white" ? "black" : "white",
          fontSize: "28px",
        }}
      >
        Xiaoxi Chen
      </Link>
    </div>
  );
};

export default Logo;
