import React, { useEffect, useState } from "react";
import { Params, useParams } from "react-router-dom";
import useAxios from "hooks/useAxios";
import classNames from "classnames";
import Nav from "components/Nav";
import FooterBasic from "components/Footer/Basic";
import styles from "./ProductDetails.module.scss";
import { data as jsonProducts } from "../../mocks/apis/products";

const ProductDetails = () => {
  const { slug } = useParams<Params>();

  const [product, setProduct] = useState<Product | null>(null);
  // const {
  //   data: response,
  //   error,
  //   loading,
  // } = useAxios(`${process.env.REACT_APP_API_HOST}/product/${slug}`);
  // const product = (response as ResponseData).data as Product;

  const data = jsonProducts as Product[];
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const currentProduct = data.find((x) => x.slug === slug);
      if (currentProduct) {
        setProduct(currentProduct);
      }
    }
  }, [data]);

  return (
    <>
      <Nav bgColor="white" />

      {product ? (
        <div className={styles["section-hero-products"]}>
          <div className={styles["wrapper-product"]}>
            <img
              src={product.images.main}
              alt=""
              sizes="(max-width: 767px) 100vw, 55vw"
              srcSet={`${product.images.thumbnail} 500w, ${product.images.main} 740w`}
              className={styles["image-hero-product"]}
            />
          </div>
          <div className={styles["wrapper-text-product"]}>
            <div className={styles["wrapper-text"]}>
              <h2 className={styles["heading-product-page"]}>{product.name}</h2>
              {/* <h5 className={styles["price-product"]}>
                £&nbsp;{product.price.numberDecimal}
              </h5> */}
              <p className={styles["paragraph-product"]}>{product.info}</p>
              <div className={styles["add-to-cart"]}>
                <form className={styles["w-commerce-commerceaddtocartform"]}>
                  {/* <div className={styles["quantity-wrapper"]}>
                    <label
                      htmlFor="quantity-product-1"
                      className={styles["field-label"]}
                    >
                      Quantity
                    </label>
                    <input
                      type="number"
                      pattern="^[0-9]+$"
                      inputMode="numeric"
                      id="quantity-product-1"
                      name="commerce-add-to-cart-quantity-input"
                      min="1"
                      className={classNames(
                        styles["w-commerce-commerceaddtocartquantityinput"],
                        styles["quantity"]
                      )}
                      defaultValue={"1"}
                    />
                  </div>

                  <input
                    type="submit"
                    defaultValue={"Add To Cart"}
                    className={classNames(
                      styles["w-commerce-commerceaddtocartbutton"],
                      styles["add-to-cart-button"]
                    )}
                  />
                  <a
                    href="/checkout"
                    className={classNames(
                      styles["w-commerce-commercebuynowbutton"],
                      styles["add-to-cart-button "],
                      "w-dyn-hide"
                    )}
                  >
                    Buy now
                  </a> */}
                </form>
                <div
                  style={{ display: "none" }}
                  className={styles["w-commerce-commerceaddtocartoutofstock"]}
                >
                  <div className={styles["text-block-13"]}>
                    This product is out of stock.
                  </div>
                </div>

                <div
                  data-node-type="commerce-add-to-cart-error"
                  style={{ display: "none" }}
                  className={styles["w-commerce-commerceaddtocarterror"]}
                >
                  <div
                    data-node-type="commerce-add-to-cart-error"
                    className={styles["error-message"]}
                    data-w-add-to-cart-quantity-error="Product is not available in this quantity."
                    data-w-add-to-cart-general-error="Something went wrong when adding this item to the cart."
                    data-w-add-to-cart-mixed-cart-error="You can’t purchase another product with a subscription."
                    data-w-add-to-cart-buy-now-error="Something went wrong when trying to purchase this item."
                    data-w-add-to-cart-checkout-disabled-error="Checkout is disabled on this site."
                    data-w-add-to-cart-select-all-options-error="Please select an option in each set."
                  >
                    Product is not available in this quantity.
                  </div>
                </div>
              </div>

              <h5 className={styles["heading-description-product"]}>
                Descriptions and details
              </h5>
              <p className={styles["paragraph-description-product"]}>
                {product.description}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p>No product found!</p>
      )}

      <FooterBasic />
    </>
  );
};

export default ProductDetails;
