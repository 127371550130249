import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import About from "pages/About";
import Blog from "pages/Blog";
import Contact from "pages/Contact";
import Shop from "pages/Shop";
import Post from "pages/Blog/Post";
import Product from "pages/Shop/ProductDetails";
import styles from "./App.module.scss";

function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<Post />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/product/:slug" element={<Product />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
