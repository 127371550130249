import { IFeed } from "@_hooks/useInstagramFeeds";
import React from "react";
import "./feed.css";

export interface IFeedProps {
  feed: IFeed;
}

export const Feed: React.FC<IFeedProps> = ({ feed }) => {
  const { id, caption, media_type, media_url, thumbnail_url } = feed;
  let post;

  switch (media_type) {
    case "VIDEO":
      post = (
        <div
          style={{
            height: "70%",
          }}
          className="wrapper-video-blog-post"
        >
          <video height="100%" width="auto" poster={thumbnail_url}>
            <source src={media_url} type="video/mp4"></source>
          </video>
        </div>
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <div
          style={{
            height: "70%",
            backgroundImage: `url(${media_url})`,
          }}
          className="wrapper-image-blog-post"
        ></div>
      );
      break;
    default:
      post = (
        <div
          style={{
            height: "70%",
            backgroundImage: `url(${media_url})`,
          }}
          className="wrapper-image-blog-post"
        ></div>
      );
  }

  return <>{post}</>;
};
