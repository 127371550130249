import React, { useState, FormEvent } from "react";
import emailjs from "@emailjs/browser";
import classNames from "classnames";
import Nav from "components/Nav";
import Footer from "components/Footer";
import styles from "./index.module.scss";

const Contact = () => {
  const [sendButtonLoading, setSendButtonLoading] = useState<boolean>(false);

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    setSendButtonLoading(true);

    // empty all invalidated message
    const validateItems = document.getElementsByClassName("validate");
    for (let i = 0; i < validateItems.length; ++i) {
      const item: HTMLElement = validateItems[i] as HTMLElement;
      item.innerHTML = "";
      item.style.display = "none";
    }

    const formElement = e.target as HTMLFormElement;
    const isValid = formElement.checkValidity();

    const sentMessage = formElement.querySelector(
      ".sent-message"
    ) as HTMLElement;
    sentMessage.style.display = "none";

    const errorMessage = formElement.querySelector(
      ".error-message"
    ) as HTMLElement;

    formElement.classList.add("submitted");
    const firstInvalidField = formElement.querySelector(":invalid") as
      | HTMLInputElement
      | HTMLTextAreaElement;

    firstInvalidField?.focus();

    if (isValid) {
      errorMessage.style.display = "none";
      const dataObj = new FormData(formElement);
      // console.log("dataObj", dataObj);

      const templateParams = {
        from_name: dataObj.get("name"),
        message: dataObj.get("message"),
        subject: dataObj.get("subject"),
      };

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID as string,
          templateParams,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
            setSendButtonLoading(false);
            sentMessage.style.display = "block";
            formElement.classList.remove("submitted");
            formElement.reset();
          },
          (err) => {
            console.log("FAILED...", err);
            setSendButtonLoading(false);
          }
        );
    } else {
      errorMessage.style.display = "block";
      setSendButtonLoading(false);
    }
  };

  const handleOnInvalid = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    errorMsg: string
  ) => {
    const formElement = e.target as HTMLInputElement | HTMLTextAreaElement;
    const fieldErrorElement = formElement
      .closest("div")
      ?.querySelector(".validate") as HTMLElement;

    fieldErrorElement.innerHTML = "";
    fieldErrorElement.style.display = "block";
    fieldErrorElement?.append(errorMsg);
  };

  return (
    <>
      <Nav bgColor="black" />

      <div className={styles["section-form-contact"]}>
        <div className={styles["filter"]}></div>
        <div className={styles["wrapper-form-contact"]}>
          <div className={styles["wrapper-text-form"]}>
            {/* <div className={styles["wrapper-address"]}>
              <h4 className={styles["heading-contact"]}>Address</h4>
              <div className={styles["text-address"]}>
                Address 123
                <br />
                city and postcode
              </div>
              <div className={styles["text-address"]}>
                Hours: M-F, 9 AM - 5 PM ET
              </div>
            </div> */}
            <div className={styles["wrapper-address"]}>
              <div className={styles["text-mail"]}>
                Email: contact@xiaoxichen.com
                <br />
              </div>
              {/* <div className={styles["text-phone"]}>
                Phone: +44 111 111 1111
                <br />
              </div> */}
              {/* <div className={styles["text-fax"]}>
                Fax: 020 1234 1234
                <br />
              </div> */}
            </div>
          </div>
          <div className={styles["form-contact"]}>
            <form
              id="contact-form"
              name="contact-form"
              className="contact-form"
              data-name="Contact Form"
              noValidate
              onSubmit={handleSubmitForm}
            >
              <div className={styles["wrapper-form"]}>
                <div>
                  <input
                    type="text"
                    className={classNames(styles["text-field-name"], "w-input")}
                    maxLength={256}
                    name="name"
                    data-name="Name"
                    placeholder="Name"
                    id="name"
                    required
                    minLength={4}
                    onInvalid={(e) =>
                      handleOnInvalid(e, "Please enter at least 4 chars")
                    }
                  />
                  <div
                    className={classNames(
                      styles["validate"],
                      "w-form-fail",
                      "validate"
                    )}
                  ></div>
                </div>

                <div>
                  <input
                    type="email"
                    className={classNames(
                      styles["text-field-email"],
                      "w-input"
                    )}
                    maxLength={256}
                    name="email"
                    data-name="Email"
                    placeholder="Email"
                    id="email"
                    required
                    onInvalid={(e) =>
                      handleOnInvalid(e, "please enter a valid email")
                    }
                  />
                  <div
                    className={classNames(
                      styles["validate"],
                      "w-form-fail",
                      "validate"
                    )}
                  ></div>
                </div>
              </div>

              <div>
                <input
                  type="text"
                  className={classNames(
                    styles["text-field-subject"],
                    "w-input"
                  )}
                  name="subject"
                  data-name="Subject"
                  placeholder="Subject"
                  id="subject"
                  minLength={4}
                  required
                  onInvalid={(e) =>
                    handleOnInvalid(e, "please enter a subject")
                  }
                />
                <div
                  className={classNames(
                    styles["validate"],
                    "w-form-fail",
                    "validate"
                  )}
                ></div>
              </div>

              <div>
                <textarea
                  placeholder="Example Text"
                  maxLength={5000}
                  id="message"
                  name="message"
                  data-name="message"
                  className={classNames(styles["textarea"], "w-input")}
                  required
                  onInvalid={(e) =>
                    handleOnInvalid(e, "Please write something for us")
                  }
                ></textarea>
                <div
                  className={classNames(
                    styles["validate"],
                    "w-form-fail",
                    "validate"
                  )}
                ></div>
              </div>

              <input
                type="submit"
                data-wait="Please wait..."
                className={classNames(
                  styles["button-form-contact"],
                  sendButtonLoading ? "loading" : ""
                )}
                value={sendButtonLoading ? "Sending..." : "Submit"}
                disabled={sendButtonLoading}
              />

              <div
                className={classNames(
                  styles["success-message-2"],
                  "w-form-done",
                  "sent-message"
                )}
              >
                <div>Thank you! Your submission has been received!</div>
              </div>

              <div className={"w-form-fail error-message"}>
                <div className={styles["text-block-16"]}>
                  Oops! Something went wrong while submitting the form.
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          data-enable-scroll="true"
          data-enable-touch="true"
          data-widget-latlng=""
          data-widget-tooltip=""
          data-widget-style="roadmap"
          data-widget-zoom="12"
          className={styles["map"]}
        ></div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
