import React, { useContext } from "react";
import { StoreContext } from "context/Store";
import classNames from "classnames";
import styles from "./Search.module.scss";
import searchIcon from "assets/images/magnifying-glass.png";
import searchIconBlack from "assets/images/magnifying-glass-black.png";

const Search = () => {
  const value = useContext(StoreContext);

  return (
    <form
      action="/search"
      className={classNames(styles["search-menu"], styles["nav"])}
    >
      <input
        type="search"
        className={classNames(
          value?.bgColor === "white"
            ? styles["input-search-black"]
            : styles["input-search"],
          value?.bgColor === "white" ? styles["nav-black"] : styles["nav"]
        )}
        maxLength={256}
        name="query"
        placeholder=""
        id="search"
        required={false}
      />
      <input
        type="image"
        value="Search"
        className={styles["icon-search"]}
        src={value?.bgColor === "white" ? searchIconBlack : searchIcon}
        alt="search"
      />
    </form>
  );
};

export default Search;
