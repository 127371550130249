import React, { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import StoreContextProvider from "context/Store";
import Logo from "components/Nav/Logo";
import Search from "components/Nav/Search";
import styles from "./index.module.scss";

const Nav = ({ bgColor }: { bgColor: BgColor }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setMenuOpen((state) => !state);
  };

  return (
    <StoreContextProvider bgColor={bgColor}>
      <div
        className={classNames(
          styles["wrapper-navbar"],
          bgColor === "white" ? styles["black"] : null
        )}
      >
        <div
          data-animation="default"
          className={styles["navbar"]}
          data-collapse={menuOpen ? "none" : "all"}
          role="banner"
        >
          <div className={styles["nav-overlay"]}></div>
          <div className={styles["wrapper-burger-menu"]}>
            <div
              className={styles["menu-button"]}
              onClick={() => {
                toggleMenu();
              }}
            >
              <div
                className={classNames(styles["menu"], styles["menu-1"])}
              ></div>
              <div
                className={classNames(styles["menu"], styles["menu-2"])}
              ></div>
              <div
                className={classNames(styles["menu"], styles["menu-3"])}
              ></div>
            </div>
          </div>

          <Logo />
          <Search />

          <nav role="navigation" className={styles["nav-menu"]}>
            <div className={styles["wrapper-men"]}>
              <div className={styles["grid-menu-open"]}>
                <div className={styles["list-menu"]}>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Love at River Thames
                      <br />
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Tiger Tiger
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Filigree Flower
                    </Link>
                  </div>
                </div>
                {/* <div className={styles["list-menu"]}>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Her
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Him
                      <br />
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Special moments
                    </Link>
                  </div>
                </div> */}
                <div className={styles["list-menu"]}>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Engagement rings
                      <br />
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Wedding bands
                      <br />
                    </Link>
                  </div>
                  {/* <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Women's wedding bands
                      <br />
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to="/shop" className={styles["link-menu"]}>
                      Men's wedding bands
                      <br />
                    </Link>
                  </div> */}
                </div>
                <div className={styles["list-menu"]}>
                  <h3 className={styles["heading-menu"]}>Pages</h3>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to={"/about"} className={styles["link-menu"]}>
                      About
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to={"/contact"} className={styles["link-menu"]}>
                      Contact
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to={"/blog"} className={styles["link-menu"]}>
                      Blog
                    </Link>
                  </div>
                  <div className={styles["wrapper-link-menu"]}>
                    <Link to={"/shop"} className={styles["link-menu"]}>
                      Shop
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div className={styles["rest-page-overlay"]}></div>
        </div>
      </div>
    </StoreContextProvider>
  );
};

export default Nav;
