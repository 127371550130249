import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import useAxios from "hooks/useAxios";
import HeroSection from "components/HeroSection";
import Nav from "components/Nav";
import Footer from "components/Footer";
import Slideshow from "components/Slideshow";
import styles from "./index.module.scss";
import { data as jsonProducts } from "../../mocks/apis/products";

const Shop = () => {
  const [products, setProducts] = useState<Product[] | {}>([]);
  // const {
  //   data: response,
  //   error,
  //   loading,
  // } = useAxios(`${process.env.REACT_APP_API_HOST}/products`);

  // const data = (response as ResponseData).data as Product[];
  const data = jsonProducts as Product[];
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setProducts(data);
    }
  }, [data]);

  const handleCategoryFilter = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    id: string
  ) => {
    e.preventDefault();
    if (Array.isArray(data) && data.length > 0) {
      const filteredProducts = data.filter((product) => {
        return product.categories.includes(id);
      });

      setProducts(filteredProducts);
    }
  };

  return (
    <>
      <Nav bgColor="white" />

      <div className={styles["shop"]}>
        <HeroSection pageTitle="Our designs" heroType="image-right">
          <>
            A memorable day needs superlative craftsmanship that will be
            treasured always.
          </>
        </HeroSection>
      </div>

      {/* <div className={classNames(styles["hero-shop-page"], "wf-section")}>
        <div className={styles["shop-hero-wrapper"]}>
          <h1 className={styles["shop-heading"]}>10% OFF</h1>
          <p className={styles["paragraph"]}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            varius enim in eros elementum tristique.
          </p>
        </div>
      </div> */}

      <div
        className={classNames(styles["section-card-shop-page"], "wf-section")}
      >
        {/* <div className={styles["category"]}>
          <div className={styles["category-collection"]}>
            <div role="list" className={styles["collection-list"]}>
              <div role="listitem" className={styles["collection-item-7"]}>
                <Link
                  to="/"
                  className={classNames(
                    styles["category-link"],
                    "w-inline-block"
                  )}
                  onClick={(e) => handleCategoryFilter(e, "1")}
                >
                  <div className={styles["category-label"]}>Wedding</div>
                </Link>
              </div>

              <div role="listitem" className={styles["collection-item-7"]}>
                <Link
                  to="/"
                  className={classNames(
                    styles["category-link"],
                    "w-inline-block"
                  )}
                  onClick={(e) => handleCategoryFilter(e, "2")}
                >
                  <div className={styles["category-label"]}>Gift</div>
                </Link>
              </div>

              <div role="listitem" className={styles["collection-item-7"]}>
                <Link
                  to="/"
                  className={classNames(
                    styles["category-link"],
                    "w-inline-block"
                  )}
                  onClick={(e) => handleCategoryFilter(e, "3")}
                >
                  <div className={styles["category-label"]}>Jewellry</div>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className={styles["collection-list-shop"]}>
          <div role="list" className={styles["grid-collection-list-shop"]}>
            {Array.isArray(products) && products.length > 0 ? (
              products.map((product) => (
                <div
                  key={product._id}
                  role="listitem"
                  className={styles["collection-items-shop"]}
                >
                  <div className={styles["wrapper-image-collection-shop"]}>
                    <div className={styles["wrapper-text-card-shop"]}>
                      <h3 className={styles["heading-card-shop"]}>
                        {product.name}
                      </h3>
                      <div className={styles["wrapper-button-shop"]}>
                        {/* <div className={styles["price"]}>
                          £&nbsp;{product.price.numberDecimal}
                        </div> */}
                        <Link
                          to={`/product/${product.slug}`}
                          className={styles["link-card-shop"]}
                        >
                          view the details
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={styles["wrapper-image-card-shop"]}>
                    <Slideshow
                      slideName={`slide-${product._id}`}
                      images={
                        product.images.slides &&
                        product.images.slides.length > 0
                          ? product.images.slides
                          : [product.images.main]
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <p>No product found!</p>
            )}

            {/* <div role="listitem" className={styles["collection-items-shop"]}>
              <div className={styles["wrapper-image-collection-shop"]}>
                <div className={styles["wrapper-text-card-shop"]}>
                  <h3 className={styles["heading-card-shop"]}>Our Product</h3>
                  <div className={styles["wrapper-button-shop"]}>
                    <div className={styles["price"]}>£123,4.00</div>
                    <Link to="/product/1" className={styles["link-card-shop"]}>
                      view the details
                    </Link>
                  </div>
                </div>
              </div>
              <div className={styles["wrapper-image-card-shop"]}>
                <Slideshow
                  slideName={"slide-2"}
                  images={[slideImg1, slideImg2]}
                />
              </div>
            </div>*/}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Shop;
