import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

const Slideshow = ({
  slideName,
  images,
}: {
  slideName: string;
  images: string[];
}) => {
  const [slideIndex, setSlideIndex] = useState<number>(1);
  useEffect(() => {
    showSlides(slideIndex);
  }, [slideIndex]);

  // Next/previous controls
  const plusSlides = (n: number) => {
    setSlideIndex((slideIndex) => slideIndex + n);
  };

  const showSlides = (n: number) => {
    let i;
    const slides: any = document.getElementsByClassName(`${slideName}`);
    if (n > slides.length) {
      setSlideIndex(1);

      return;
    }
    if (n < 1) {
      setSlideIndex(slides.length);

      return;
    }

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slides[slideIndex - 1].style.display = "block";
  };

  return (
    <div className={styles["slideshow-container"]}>
      {images.length > 0 &&
        images.map((image, key) => (
          <div
            key={`${slideName}-${key}`}
            className={classNames(
              `${slideName}`,
              styles["mySlides"],
              styles["fade"]
            )}
          >
            <img src={image} style={{ width: "100%" }} />
          </div>
        ))}

      <a className={styles["prev"]} onClick={() => plusSlides(-1)}>
        &#10094;
      </a>
      <a className={styles["next"]} onClick={() => plusSlides(1)}>
        &#10095;
      </a>
    </div>
  );
};

export default Slideshow;
