import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import styles1 from "./heroSectionImgLeft.module.scss";
import styles2 from "./heroSectionImgRight.module.scss";

const HeroSection = ({
  pageTitle,
  children,
  heroType = "default",
}: {
  pageTitle: string;
  children: JSX.Element;
  heroType?: string;
}) => {
  const [pageLoad, setPageLoad] = useState<string>("no");
  useEffect(() => {
    setPageLoad("init");
  }, []);

  if (heroType === "image-left") {
    return (
      <div
        data-page-load={pageLoad}
        className={styles1["section-hero-img-left"]}
      >
        <div className={styles1["section-hero-img-left-content"]}>
          {/* <div style="-webkit-transform:translate3d(500PX, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(500PX, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(500PX, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(500PX, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" className={styles["wrapper-text-about"]}> */}
          <div className={styles1["wrapper-text"]}>
            <h2 className={styles1["heading"]}>{pageTitle}</h2>
            <p className={styles1["paragraph"]}>{children}</p>
          </div>
          <div className={styles1["wrapper-background"]}></div>
        </div>
      </div>
    );
  }

  if (heroType === "image-right") {
    return (
      <div
        data-page-load={pageLoad}
        className={styles2["section-hero-img-right"]}
      >
        <div className={styles2["section-hero-img-right-content"]}>
          <div className={styles2["wrapper-background"]}></div>
          <div className={styles2["wrapper-text"]}>
            <h2 className={styles2["heading"]}>{pageTitle}</h2>
            <p className={styles2["paragraph"]}>{children}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div data-page-load={pageLoad} className={styles["section-hero"]}>
      <div className={styles["wrapper-bg"]}>
        <h2 className={styles["hero-heading"]}>{pageTitle}</h2>
      </div>
      <div className={styles["wrapper-content"]}>{children}</div>
    </div>
  );
};

export default HeroSection;
