import product1img1 from "@_assets/images/shop/product-1.main.png";
import product1img2 from "@_assets/images/shop/product-1-2.jpg";
import product2img1 from "@_assets/images/shop/product-2.main.png";
import product2img2 from "@_assets/images/shop/product-2-2.png";
import product3img1 from "@_assets/images/shop/product-3.main.png";
import product3img2 from "@_assets/images/shop/product-3-2.jpg";

export const data = [
  {
    _id: "c7b3d8e0-5e0b-4b0f-8b3a-3b9f4b3d3b3d",
    name: "Tiger Tiger",
    type: "",
    slug: "tiger-tiger",
    info: "",
    description:
      'The "Love at River Thames" collection draws inspiration from the rich history of romance along the iconic river. The Thames has witnessed countless love stories, from secret whispers on its banks to grand proposals on its bridges. Each piece in this collection captures the essence of these timeless moments, blending elegance with the fluidity of the river.',
    categories: ["3"],
    images: {
      main: product1img1,
      thumbnail: "",
      slides: [product1img1, product1img2],
    },
    price: {
      numberDecimal: 1000,
    },
    tax_class: "",
    requires_shipping: false,
    created_on: "26/08/2024",
    updated_on: "26/08/2024",
    published_on: "26/08/2024",
  },
  {
    _id: "c7b3d8e0-5e0b-4b0f-8b3a-3b9f4b3d3b3e",
    name: "Filigree Flower",
    type: "",
    slug: "filigree-flower",
    info: "",
    description:
      'The "Filigree Flower" collection honors the rich tradition of Chinese filigree craftsmanship, blending this ancient art with the timeless elegance of pearls. Each piece features intricate gold filigree that mirrors the delicate beauty of blooming flowers, while luminous pearls add a touch of classic sophistication. This collection celebrates the fusion of tradition and luxury, creating jewellery as enchanting and refined as a timeless garden.',
    categories: ["2"],
    images: {
      main: product2img1,
      thumbnail: "",
      slides: [product2img1, product2img2],
    },
    price: {
      numberDecimal: 1000,
    },
    tax_class: "",
    requires_shipping: false,
    created_on: "2024-08-26",
    updated_on: "2024-08-26",
    published_on: "2024-08-26",
  },
  {
    _id: "c7b3d8e0-5e0b-4b0f-8b3a-3b9f4b3d3b3f",
    name: "Love at River Thames",
    type: "",
    slug: "love-at-river-thames",
    info: "",
    description:
      'The "Love at River Thames" collection draws inspiration from the rich history of romance along the iconic river. The Thames has witnessed countless love stories, from secret whispers on its banks to grand proposals on its bridges. Each piece in this collection captures the essence of these timeless moments, blending elegance with the fluidity of the river.',
    categories: ["1"],
    images: {
      main: product3img1,
      thumbnail: "",
      slides: [product3img1, product3img2],
    },
    price: {
      numberDecimal: 1000,
    },
    tax_class: "",
    requires_shipping: false,
    created_on: "2024-08-26",
    updated_on: "2024-08-26",
    published_on: "2024-08-26",
  },
];
