import React, { createContext } from "react";

export const StoreContext = createContext<null | { bgColor: BgColor }>(null);

const StoreContextProvider = ({
  bgColor,
  children,
}: {
  bgColor: BgColor;
  children: JSX.Element;
}) => {
  const store = {
    bgColor,
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export default StoreContextProvider;
