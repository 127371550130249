import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "./normalize.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const { worker } = await import("./mocks/browser");

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start({
    onUnhandledRequest(req, print) {
      if (!req.url.includes(process.env.REACT_APP_API_HOST!)) {
        return;
      }

      print.warning();
    },
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

enableMocking().then(() => {
  root.render(<App />);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
