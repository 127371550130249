import { useState, useEffect } from "react";
import axios from "axios";

export interface IFeed {
  id: string;
  caption: string;
  media_type: string;
  media_url: string;
  thumbnail_url: string;
  timestamp: string;
}

const useInstagramFeeds = (token: string, limit: number) => {
  const [feeds, setFeedsData] = useState<IFeed[]>([]);

  useEffect(() => {
    // this is to avoid memory leaks
    const abortController = new AbortController();
    const signal = abortController.signal;

    async function fetchInstagramPost() {
      try {
        axios
          .get(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption,timestamp,thumbnail_url&limit=${limit}&access_token=${token}`,
            { signal }
          )
          .then((resp) => {
            setFeedsData(resp.data.data);
          });
      } catch (err) {
        console.log("error", err);
      }
    }

    fetchInstagramPost();

    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort();
    };
  }, []);

  return feeds;
};

export default useInstagramFeeds;
