import React, { useEffect, useState } from "react";
import { Params, useParams } from "react-router-dom";
import { sanitize } from "dompurify";
import useAxios from "hooks/useAxios";
import Nav from "components/Nav";
import FooterBasic from "components/Footer/Basic";
import styles from "./Post.module.scss";
import { data as jsonBlogs } from "../../mocks/apis/blogs";
import useInstagramFeeds, { IFeed } from "@_hooks/useInstagramFeeds";

const instagramAccessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN ?? "";
const instagramFeedLimit = Number(
  process.env.REACT_APP_INSTAGRAM_FEED_LIMIT ?? 0
);

interface IMediaProps {
  feed: IFeed;
}

const Media: React.FC<IMediaProps> = ({ feed }) => {
  const { media_type, media_url, caption, thumbnail_url } = feed;
  let post;

  switch (media_type) {
    case "VIDEO":
      post = (
        <video
          width="100%"
          height="380"
          controls
          playsInline
          poster={thumbnail_url}
        >
          <source src={media_url} type="video/mp4"></source>
        </video>
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <img
          width="100%"
          height="auto"
          src={media_url}
          alt={caption.substring(0, 20) + "..."}
        />
      );
      break;
    default:
      post = (
        <img
          width="100%"
          height="auto"
          src={media_url}
          alt={caption.substring(0, 20) + "..."}
        />
      );
  }

  return <>{post}</>;
};

const Post: React.FC = () => {
  const [blog, setBlog] = useState<Blog | null>(null);
  const feeds = useInstagramFeeds(instagramAccessToken, instagramFeedLimit);

  const { slug } = useParams<Params>();
  // const { data, error, loading } = useAxios(
  //   `${process.env.REACT_APP_API_HOST}/blog/${slug}`
  // );

  // const response = (data as ResponseData).data as Blog;

  const data = jsonBlogs as Blog[];
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      const currentBlog = data.find((x) => x.slug === slug);
      if (currentBlog) {
        setBlog(currentBlog);
      }
    }

    if (feeds && feeds.length > 0) {
      const blogFeeds: Blog[] = feeds.map((feed) => ({
        _id: feed.id,
        name: feed.caption.substring(0, 20) + "...",
        slug: feed.id,
        created_on: feed.timestamp,
        updated_on: feed.timestamp,
        published_on: feed.timestamp,
        body: feed.caption,
        summary: feed.caption.substring(0, 50) + "...",
        images: {
          main: undefined,
          thumbnail: undefined,
        },
        instagram: true,
        instagram_feed: feed,
        featured: true,
        color: "#000",
      }));

      const currentBlog = blogFeeds.find((x) => x.slug === slug);
      if (currentBlog) {
        setBlog(currentBlog);
      }
    }
  }, [data, feeds]);

  return (
    <>
      <Nav bgColor="white" />

      <>
        <div className={styles["section-hero-blog-post"]}>
          {blog ? (
            <>
              <div className={styles["wrapper-image-post"]}>
                {blog.instagram ? (
                  <Media feed={blog.instagram_feed!} />
                ) : (
                  <img
                    width="100%"
                    height="auto"
                    src={blog.images.main}
                    alt={blog.name}
                  />
                )}
              </div>
              <div className={styles["wrapper-text-blog-post"]}>
                <div className={styles["content-blog-post"]}>
                  <>
                    <h1 className={styles["heading-blog-post"]}>{blog.name}</h1>
                    <div className={styles["text-date"]}>
                      {new Date(blog.published_on).toLocaleDateString()}
                    </div>
                    <div
                      className={styles["w-richtext"]}
                      dangerouslySetInnerHTML={{
                        __html: sanitize(blog.body, {
                          USE_PROFILES: { html: true },
                        }),
                      }}
                    ></div>
                  </>

                  {/* <h1 className={styles["heading-blog-post"]}>
                Perfect alone, great together. Discover how to mix, match and
                mismatch your jems
              </h1>
              <div className={styles["text-date"]}>October 14, 2019</div>
              <div className={"w-richtext"}>
                <h2>Velit amet exercitationem est et.</h2>
                <p>
                  Animi qui ea repudiandae earum eos praesentium quae
                  accusantium omnis. Omnis necessitatibus et hic quia.
                  Blanditiis saepe voluptatem perferendis quod quaerat
                  voluptatem omnis. Veniam similique nulla enim sit aut nisi
                  dolor odio explicabo.
                </p>
                <h3>
                  Cupiditate dicta blanditiis sit non sapiente unde ut esse.
                </h3>
                <blockquote>
                  Reiciendis occaecati in et veritatis. Quam fuga ab molestiae
                  molestias optio nobis amet sed in. Possimus fugit officiis et
                  voluptatem cum quibusdam. Laborum aut et odit necessitatibus
                  architecto autem nam molestiae. Laboriosam in odit qui
                  expedita iusto. Est necessitatibus unde dicta incidunt.
                </blockquote>
                <p>
                  Ducimus libero at ut a repellendus. Laudantium voluptate sit
                  magnam consequatur laboriosam error perferendis ut. Laudantium
                  aut nisi architecto soluta est laboriosam nulla dolore. Iusto
                  necessitatibus voluptatem perferendis voluptatem quia. Totam
                  cumque possimus voluptatem corrupti odio. Reprehenderit
                  quibusdam quia corporis nam.
                </p>
                <p>
                  Veniam odit quis autem qui dolores. Corporis aut illum eum
                  ipsa. Sequi odit laudantium veritatis iure ex autem unde.
                </p>
              </div> */}
                </div>
              </div>
            </>
          ) : (
            <p>No blog found !</p>
          )}
        </div>
      </>

      <FooterBasic />
    </>
  );
};

export default Post;
