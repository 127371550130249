import React from "react";
import classNames from "classnames";
import Nav from "@_components/Nav";
import HeroSection from "@_components/HeroSection";
import Footer from "components/Footer";
import styles from "./index.module.scss";

const About = () => {
  return (
    <>
      <Nav bgColor="white" />

      <div className={styles["about"]}>
        <HeroSection pageTitle="About us">
          <>
            <p className={styles["paragraph-about-page"]}>
              Xiaoxi Chen's jewellery transcends mere adornment; it serves as a
              tangible narrative that bridges the past, present, and future.
              Each piece tells a story, transforming personal experiences and
              historical moments into wearable art. Her designs are an exquisite
              blend of cultural tradition and modern innovation, creating a
              dialogue between time-honored craftsmanship and contemporary
              aesthetics.
            </p>

            <p className={styles["paragraph-about-page"]}>
              Working from her home studio in the heart of London, Xiaoxi
              creates jewellery that reflects her deep connection to both
              heritage and forward-thinking design. Recognized with awards and
              featured in exhibitions, her work stands as a testament to her
              unique artistic vision.
            </p>
          </>
        </HeroSection>
        <div className={styles["grid-about"]}>
          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_1"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_1"]
            )}
          >
            <h3 className={styles["date"]}>2017</h3>
            <p className={styles["paragraph-grid"]}>
              Xiaoxi Chen has achieved the highest standards of excellence in
              the "A Quest for Glamour" competition in the UK.
            </p>
            <div className={styles["wrapper-br-color"]}></div>
          </div>

          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_2"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_2"]
            )}
          >
            <h3 className={styles["white"]}>2017</h3>
            <p className={classNames(styles["paragraph-grid"])}>
              Xiaoxi Chen is showcasing her three rings in the exhibition "
              <a href="https://www.hochschule-trier.de/en/hochschule/organisation/serviceeinrichtungen/aaa/projekte-und-veranstaltungen/hochschule-trier-international/internationale-lehr-und-transferaktivitaeten/translate-to-englisch-a-ring-is-a-ring-is-a-ring">
                A Ring is a Ring is a Ring...
              </a>
              " following a year-long ERASMUS+ project in Europe.
            </p>
          </div>

          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_3"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_3"]
            )}
          >
            <h3 className={styles["date"]}>2018</h3>
            <p className={styles["paragraph-grid"]}>
              Xiaoxi Chen has been awarded the Silver Award in Precious
              Jewellery at the UK Goldsmiths’ Craft & Design Council Awards.
            </p>
          </div>

          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_4"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_4"]
            )}
          >
            <h3 className={styles["date"]}>2018</h3>
            <p className={styles["paragraph-grid"]}>
              Xiaoxi Chen is presenting her three bangles in the "VALUING AMBER"
              exhibition in Beijing, China.
            </p>
            <div className={styles["wrapper-bg-color"]}></div>
          </div>

          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_5"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_5"]
            )}
          >
            <h3 className={classNames(styles["date"])}>2018</h3>
            <p className={classNames(styles["paragraph-grid"])}>
              Xiaoxi Chen is showcasing her three bangles in the "VALUING AMBER"
              exhibition at the London Design Festival.
            </p>
          </div>

          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_6"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_6"]
            )}
          >
            <h3 className={styles["date"]}>2019</h3>
            <p className={styles["paragraph-grid"]}>
              Xiaoxi Chen is launching and exhibiting her new silver jewellery
              collections at the "New Designers 2019 - One Year In" event, held
              at the Business Design Centre in London from June 26th to 29th.
            </p>
          </div>

          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_7"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_7"]
            )}
          >
            <h3 className={styles["date"]}>2024</h3>
            <p className={styles["paragraph-grid"]}>
              Xiaoxi is exhibiting her new fine jewellery collections at the UK
              Jewellery Show in Olympia.
            </p>
            <div className={styles["wrapper-bg-color-about"]}></div>
          </div>

          {/* <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_7"]
            )}
          >
            <h3 className={styles["date"]}>2012</h3>
            <p className={styles["paragraph-grid"]}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi.
            </p>
            <div className={styles["wrapper-bg-color-about"]}></div>
          </div>
          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_7"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_8"]
            )}
          ></div>
          <div className={styles["wrapper-text-about-page"]}>
            <h3 className={styles["date"]}>2015</h3>
            <p className={styles["paragraph-grid"]}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi.
            </p>
          </div>
          <div className={styles["wrapper-text-about-page"]}>
            <h3 className={styles["date"]}>2018</h3>
            <p className={styles["paragraph-grid"]}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi.
            </p>
          </div>
          <div className={styles["wrapper-image-about-page"]}></div>
          <div
            className={classNames(
              styles["wrapper-image-about-page"],
              styles["_10"]
            )}
          ></div>
          <div
            className={classNames(
              styles["wrapper-text-about-page"],
              styles["_10"]
            )}
          >
            <h3 className={styles["date"]}>2019</h3>
            <p className={styles["paragraph-grid"]}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi.
            </p>
            <div className={styles["wrapper-bg-grid"]}></div>
          </div> */}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default About;
