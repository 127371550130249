import React from "react";
import classNames from "classnames";
import fbIcon from "assets/images/fb.svg";
import instagramIcon from "assets/images/instagram.svg";
import twitterIcon from "assets/images/twitter.svg";
import styles from "./Basic.module.scss";

const Basic: React.FC = () => {
  return (
    <div className={styles["footer-black"]}>
      <div className={styles["wrapper-footer-black"]}>
        <div className={styles["copyright-footer-black"]}>
          ©Copyright {new Date().getFullYear()} - Xiaoxi Chen
        </div>
        <div className={styles["social"]}>
          <a
            href="https://www.facebook.com"
            target="_blank"
            className={classNames(
              styles["wrapper-link-social-black"],
              "w-inline-block"
            )}
            rel="noreferrer"
          >
            <img
              src={fbIcon}
              width="20"
              alt=""
              className={styles["facebook"]}
            />
          </a>
          <a
            href="https://www.instagram.com/xiaoxi.chen.jewellery/"
            target="_blank"
            className={classNames(
              styles["wrapper-link-social-black"],
              "w-inline-block"
            )}
            rel="noreferrer"
          >
            <img
              src={instagramIcon}
              width="20"
              alt=""
              className={styles["instagram"]}
            />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            className={classNames(
              styles["wrapper-link-social-black"],
              "w-inline-block"
            )}
            rel="noreferrer"
          >
            <img
              src={twitterIcon}
              width="20"
              alt=""
              className={styles["twitter"]}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Basic;
