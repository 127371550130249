import React from "react";
import Nav from "components/Nav";
import styles from "./index.module.scss";

const Home = () => {
  return (
    <>
      <Nav bgColor="black" />

      <div className={styles["hero-section"]}>
        <div className={styles["section-hover"]}>
          <div className={styles["wrapper-options"]}>
            <a href="/shop" className={styles["option"]}>
              <h4 className={styles["name-options"]}>Love at River Thames</h4>
            </a>
            <a href="/shop" className={styles["option"]}>
              <h4 className={styles["name-options"]}>Tiger Tiger</h4>
            </a>
            <a href="/shop" className={styles["option"]}>
              <h4 className={styles["name-options"]}>Filigree Flower</h4>
            </a>
          </div>
        </div>
        <div className={styles["section-hover"]}>
          <div className={styles["wrapper-options"]}>
            <a href="/shop" className={styles["option"]}>
              <h4 className={styles["name-options"]}>Engagement rings</h4>
            </a>
            <a href="/shop" className={styles["option"]}>
              <h4 className={styles["name-options"]}>
                Wedding bands
                <br />
              </h4>
            </a>
            {/* <a href="/shop" className={styles["option"]}>
              <h4 className={styles["name-options"]}>Him</h4>
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
