import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import fbIcon from "assets/images/fbB.svg";
import instagramIcon from "assets/images/instagramB.svg";
import twitterIcon from "assets/images/twitterB.svg";

const Footer = () => {
  return (
    <div className={styles["footer"]}>
      <div className={styles["content-sub-menu"]}>
        <div className={styles["container-brand"]}>
          <Link to="/" className={styles["link-brand-footer"]}>
            Xiaoxi Chen
          </Link>
        </div>
        <div className={styles["copyright"]}>
          ©Copyright {new Date().getFullYear()} - Xiaoxi Chen
        </div>
      </div>
      <div className={styles["content-voci-menu-footer"]}>
        <div
          className={classNames(
            styles["w-layout-grid"],
            styles["grid-menu-footer"]
          )}
        >
          <div className={styles["list-footer"]}>
            <h3 className={styles["heading-footer"]}>Navigation</h3>
            <div className={styles["wrapper-link-footer"]}>
              <Link to="/about" className={styles["link-footer"]}>
                About
              </Link>
            </div>
            <div className={styles["wrapper-link-footer"]}>
              <Link to="/contact" className={styles["link-footer"]}>
                Contact
              </Link>
            </div>
            <div className={styles["wrapper-link-footer"]}>
              <Link to="/blog" className={styles["link-footer"]}>
                Blog
              </Link>
            </div>
            <div className={styles["wrapper-link-footer"]}>
              <Link to="/shop" className={styles["link-footer"]}>
                Shop
              </Link>
            </div>
          </div>
          <div className={styles["list-footer"]}>
            <h3 className={styles["heading-footer"]}>Services</h3>
            <div className={styles["wrapper-link-footer"]}>
              <Link to="/policy" className={styles["link-footer"]}>
                Privacy Policy
              </Link>
            </div>
            <div className={styles["wrapper-link-footer"]}>
              <Link to="/tc" className={styles["link-footer"]}>
                Terms of Use
              </Link>
            </div>
          </div>
          <div className={styles["list-footer"]}>
            <h3 className={styles["heading-footer"]}>Social</h3>
            <div className={styles["wrapper-link-footer"]}>
              <a
                href="https://www.facebook.com/"
                target="_blank"
                className={styles["w-inline-block"]}
                rel="noreferrer"
              >
                <img
                  src={fbIcon}
                  width="24"
                  alt=""
                  className={styles["image-social"]}
                />
              </a>
            </div>
            <div className={styles["wrapper-link-footer"]}>
              <a
                href="https://www.instagram.com/xiaoxi.chen.jewellery/"
                target="_blank"
                className={styles["w-inline-block"]}
                rel="noreferrer"
              >
                <img
                  src={instagramIcon}
                  width="24"
                  alt=""
                  className={styles["image-social"]}
                />
              </a>
            </div>
            <div className={styles["wrapper-link-footer"]}>
              <a
                href="https://twitter.com/"
                target="_blank"
                className={styles["w-inline-block"]}
                rel="noreferrer"
              >
                <img
                  src={twitterIcon}
                  width="24"
                  alt=""
                  className={styles["image-social"]}
                />
              </a>
            </div>
          </div>
          <div className={styles["list-footer"]}>
            <h3 className={styles["heading-newsletter-footer"]}>
              Get the latest from Xiaoxi Chen
            </h3>
            <p className={styles["paragraph-newsletter-footer"]}>
              Don't miss our news about glamorous products and sparkling events
            </p>
            <div
              className={classNames(
                styles["newsletter-form-footer"],
                styles["w-form"]
              )}
            >
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                method="get"
                className={styles["newsletter-footer"]}
              >
                <input
                  type="email"
                  className={styles["text-field"]}
                  maxLength={256}
                  name="email-2"
                  data-name="Email 2"
                  placeholder="Enter your email"
                  id="email-2"
                  required={false}
                />
                <input
                  type="submit"
                  value="Submit"
                  data-wait="Please wait..."
                  className={classNames(
                    styles["submit-button"],
                    styles["w-button"]
                  )}
                />
              </form>
              <div
                className={classNames(
                  styles["success-message"],
                  styles["w-form-done"]
                )}
              >
                <div className={styles["text-block-15"]}>
                  Thank you! Your submission has been received!
                </div>
              </div>
              <div className={styles["w-form-fail"]}>
                <div className={styles["text-block-15"]}>
                  Oops! Something went wrong while submitting the form.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
