import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Nav from "components/Nav";
import HeroSection from "components/HeroSection";
import Footer from "components/Footer";
import styles from "./index.module.scss";
import { data as jsonBlogs } from "@_mocks/apis/blogs";
import useInstagramFeeds from "@_hooks/useInstagramFeeds";
import { Feed } from "@_components/InstagramFeeds/Feed";

const instagramAccessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN ?? "";
const instagramFeedLimit = Number(
  process.env.REACT_APP_INSTAGRAM_FEED_LIMIT ?? 0
);

const Blog = () => {
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const feeds = useInstagramFeeds(instagramAccessToken, instagramFeedLimit);

  // const { error, loading, data } = useAxios(
  //   `${process.env.REACT_APP_API_HOST}/blogs`
  // );
  // const response = (data as ResponseData).data as Blog[];
  const data = jsonBlogs as Blog[];
  useEffect(() => {
    if (Array.isArray(data) && data.length > 0) {
      setBlogs(data);
    }

    if (feeds && feeds.length > 0) {
      const blogFeeds: Blog[] = feeds.map((feed) => ({
        _id: feed.id,
        name: feed.caption.substring(0, 20) + "...",
        slug: feed.id,
        created_on: feed.timestamp,
        updated_on: feed.timestamp,
        published_on: feed.timestamp,
        body: feed.caption,
        summary: feed.caption.substring(0, 50) + "...",
        images: {
          main: undefined,
          thumbnail: undefined,
        },
        instagram: true,
        instagram_feed: feed,
        featured: true,
        color: "#000",
      }));

      setBlogs((currentData) =>
        currentData
          .concat(blogFeeds)
          .sort(
            (a, b) =>
              new Date(b.updated_on).getTime() -
              new Date(a.published_on).getTime()
          )
      );
    }
  }, [data, feeds]);

  return (
    <>
      <Nav bgColor="white" />

      <div className={styles["blog"]}>
        <HeroSection pageTitle="Our stories" heroType="image-left">
          <>
            A strong identity and unique story-telling soul to create fine
            jewellery creations of timeless beauty.
          </>
        </HeroSection>
      </div>

      <div className={styles["section-blog-card"]}>
        <div className={styles["collection-list-wrapper-blog"]}>
          <div role="list" className={styles["grid-collection-list"]}>
            {blogs.length > 0 ? (
              blogs.map((blog) => (
                <div
                  key={blog._id}
                  role="listitem"
                  className={styles["collection-item-blog"]}
                >
                  <Link
                    to={`/blog/${blog.slug}`}
                    className={styles["block-link"]}
                  >
                    <div className={styles["wrapper-card"]}>
                      <div
                        style={{ opacity: "0" }}
                        className={styles["wraooer-color"]}
                      ></div>

                      {blog.instagram ? (
                        <Feed feed={blog.instagram_feed!} />
                      ) : (
                        <div
                          style={{
                            height: "60%",
                            backgroundImage: `url(${blog.images.thumbnail})`,
                          }}
                          className={styles["wrapper-image-blog-post"]}
                        ></div>
                      )}

                      <div className={styles["wrapper-yexy-card-blog"]}>
                        <h2
                          style={{ color: "rgb(51,51,51)" }}
                          className={styles["heading-card-blog"]}
                        >
                          {blog.name}
                        </h2>
                        <div
                          style={{ color: "rgb(51,51,51)" }}
                          className={styles["date-blog-post"]}
                        >
                          {new Date(blog.published_on).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <p>No blogs found!</p>
            )}

            {/* <div role="listitem" className={styles["collection-item-blog"]}>
              <Link to="/blog/1" className={styles["block-link"]}>
                <div className={styles["wrapper-card"]}>
                  <div
                    style={{ opacity: "0" }}
                    className={styles["wraooer-color"]}
                  ></div>
                  <div
                    style={{ height: "60%" }}
                    className={classNames(
                      styles["wrapper-image-blog-post"],
                      styles["img-1"]
                    )}
                  ></div>
                  <div className={styles["wrapper-yexy-card-blog"]}>
                    <h2
                      style={{ color: "rgb(51,51,51)" }}
                      className={styles["heading-card-blog"]}
                    >
                      Perfect alone, great together. Discover how to mix, match
                      and mismatch your jems
                    </h2>
                    <div
                      style={{ color: "rgb(51,51,51)" }}
                      className={styles["date-blog-post"]}
                    >
                      October 23, 2019
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Blog;
